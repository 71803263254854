import React, {useEffect} from 'react';
import { TwitterTimelineEmbed } from 'react-twitter-embed';

const TwitterWall: React.FC = () => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://platform.twitter.com/widgets.js';
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return (
        <div className="twitter-timeline">
            <a
                className="twitter-timeline"
                data-theme="dark"
                data-height="500"
                href="https://twitter.com/crazyclips_?ref_src=twsrc%5Etfw"
            >
                Tweets by crazyclips_
            </a>
        </div>
    );
};


export default TwitterWall;
