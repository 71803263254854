import React, { useState } from 'react';
import logo from '../logo_pull.png'; // Assuming you have a logo image imported

import { Outlet, NavLink, Link } from "react-router-dom";

import '../App.css';

import StatsBox from "../Elements/StatsBox";
import LoadingScreen from "../Elements/LoadingScreen/LoadingScreen";
import Calendar from "../Elements/Calendar/Calendar";
import TwitterWall from "../Elements/TwitterWall";

import CountDown from "../Elements/CountDown";

const Layout: React.FC = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true); // State to track loading status
    const [loadingPercentage, setLoadingPercentage] = React.useState<number>(0);

    React.useEffect(() => {

        if(localStorage.getItem('leftSidebarHidden') === 'true') {
            const leftSidebar = document.querySelector('.leftSidebar');
            if (leftSidebar) {
                leftSidebar.classList.add('sideBarHidden');
            }
        }
        if(localStorage.getItem('rightSidebarHidden') === 'true') {
            const rightSidebar = document.querySelector('.rightSidebar');
            if (rightSidebar) {
                rightSidebar.classList.add('sideBarHidden');
            }
        }

        const interval = setInterval(() => {
            setLoadingPercentage(prevPercentage => {
                const newPercentage = prevPercentage + 50;
                if (newPercentage >= 110) {
                    setIsLoading(false);
                    clearInterval(interval);
                    return 100;
                }
                return newPercentage;
            });
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    let date = new Date();
    date.setMonth(date.getMonth() + 1);
    date.setDate(0);

    const handleDateSelect = (date: Date | null) => {
        console.log(date);
    }

    const hideLeftSidebar = () => {
        const leftSidebar = document.querySelector('.leftSidebar');
        if (leftSidebar) {
            if(leftSidebar.classList.contains('sideBarHidden')) {
                leftSidebar.classList.remove('sideBarHidden');
                // Store state in local storage
                localStorage.setItem('leftSidebarHidden', 'false');
            }
            else{
                leftSidebar.classList.add('sideBarHidden');
                // Store state in local storage
                localStorage.setItem('leftSidebarHidden', 'true');
            }
        }
    }

    const hideSidebar = () => {
        console.log('hide sidebar');
        const rightSidebar = document.querySelector('.rightSidebar');
        if (rightSidebar) {
            if(rightSidebar.classList.contains('sideBarHidden')) {
                rightSidebar.classList.remove('sideBarHidden');
                // Store state in local storage
                localStorage.setItem('rightSidebarHidden', 'false');
            }
            else{
                rightSidebar.classList.add('sideBarHidden');
                // Store state in local storage
                localStorage.setItem('rightSidebarHidden', 'true');
            }
        }
    }

    return (
        <div className="App">
            {isLoading && <LoadingScreen Percent={loadingPercentage} />}
            <>
                <div className="appContainer">
                    <div className="leftSidebar">
                        <div className="sidebarHeader">
                            <div className="sidebarHeaderTitle">
                                <h3>Sidebar</h3>
                                <button className="closeSidebar" onClick={hideLeftSidebar}>
                                    <svg className="w-[24px] h-[24px] text-gray-800 dark:text-white" aria-hidden="true"
                                         xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                                         viewBox="0 0 24 24">
                                        <path fill-rule="evenodd"
                                              d="M13.729 5.575c1.304-1.074 3.27-.146 3.27 1.544v9.762c0 1.69-1.966 2.618-3.27 1.544l-5.927-4.881a2 2 0 0 1 0-3.088l5.927-4.88Z"
                                              clip-rule="evenodd"/>
                                    </svg>
                                </button>
                            </div>
                        </div>
                        <div className="sidebarBody">
                            <div className="sideBarSection">
                                <div className="sidebarContainer">
                                    <div className="sideBarProfile">
                                        <div className="sideBarAvatarContainer">
                                            <div className="sideBarAvatar">
                                                <img src="https://image.lexica.art/full_jpg/7515495b-982d-44d2-9931-5a8bbbf27532"></img>
                                            </div>
                                        </div>
                                        <div className="sideBarProfileUsername">
                                            <h3>Ralph</h3>
                                        </div>
                                        <div className="sideBarProfilePoints">
                                            1,500
                                            <div className="pointsSymbolColor">
                                                PTS
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="center">
                        <div className="centerHeader">
                            <div className="leftColumn">
                                <ul className="mainNavigation">
                                    <li>
                                        <a href="#">MY STATS</a>
                                        <a href="#">LEADERBOARDS</a>
                                    </li>
                                </ul>
                            </div>
                            <div className="centerColumn">
                                <Link to="/dashboard">
                                    <img src={logo} width="50px" className="App-logo" alt="logo"/>
                                </Link>
                            </div>
                            <div className="rightColumn">
                                <ul className="mainNavigation">
                                    <li>
                                        <NavLink to="/dashboard">Power Posts</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/dashboard/how-to-play">How To Play</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/dashboard/calculate-points">Calculate Points</NavLink>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="centerBody">
                            <Outlet/>
                        </div>
                    </div>
                    <div className="rightSidebar">
                        <div className="sidebarHeader">
                            <div className="sidebarHeaderTitle">
                                <h3>Sidebar</h3>
                                <button className="closeSidebar" onClick={hideSidebar}>
                                    <svg className="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true"
                                         xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                                         viewBox="0 0 24 24">
                                        <path fill-rule="evenodd"
                                              d="M10.271 5.575C8.967 4.501 7 5.43 7 7.12v9.762c0 1.69 1.967 2.618 3.271 1.544l5.927-4.881a2 2 0 0 0 0-3.088l-5.927-4.88Z"
                                              clip-rule="evenodd"/>
                                    </svg>
                                </button>
                            </div>
                        </div>
                        <div className="sidebarBody">
                            <div className="sideBarSection">
                                <div className="sidebarContainer">
                                    <h3 className="sideBarTitle">Season 1 Ends in:</h3>
                                    <CountDown showUnits={{days: true, hours: true, minutes: true, seconds: true}}
                                               endDate={date}/>
                                </div>
                            </div>
                            <div className="sideBarSection">
                                <div className="sidebarContainer">
                                    <h3 className="sideBarTitle">Calendar</h3>
                                    <Calendar onSelectDate={handleDateSelect}/>
                                </div>
                            </div>
                            <div className="sideBarSection">
                                <div className="sidebarContainer">
                                    <h3 className="sideBarTitle">Leaderboard</h3>
                                    <div className="leaderboardContainer">
                                        Leaderboards coming soon...
                                    </div>
                                </div>
                            </div>
                            <div className="sideBarSection">
                                <div className="sidebarContainer twitterWall">
                                    <TwitterWall/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        </div>
    );
};

export default Layout;