import React, {useState} from 'react';
import LoadingScreen from "../Elements/LoadingScreen/LoadingScreen";
import logo from "../logo_pull.png";
import TwitterWall from "../Elements/TwitterWall";

const Calculate = () => {

    return (
        <>How to play</>
    )
};

export default Calculate;