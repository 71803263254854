import React from 'react';
import './IsometricCube.css';

interface IsometricCubeProps {
    active?: boolean;
    value: string;
    text: string;
    keyId?: number;
}

const IsometricCube: React.FC<IsometricCubeProps> = ({ active, keyId, value, text }) => {
    const cubeClass = keyId ? `cube keyId-${keyId}` : 'cube';
    const isActiveClass = active ? ' orange' : '';

    return (
        <div className="cube-container">
            <div className={`cube${isActiveClass}`}>
                <div className="side front">
                    <div className="pointsValueStat">{value} PTS</div>
                    <div className="timeValueStat">{text}</div>
                </div>
                <div className="side back"></div>
                <div className="side left"></div>
                <div className="side right"></div>
                <div className="side bottom"></div>
                <div className="side top"></div>
            </div>
        </div>
    );
};

export default IsometricCube;