import React, { useState } from 'react';
import './Calendar.css';

interface CalendarProps {
    onSelectDate: (date: Date | null) => void; // Updated to accept nullable dates
    events?: { date: Date, title: string, description: string }[];
}

const Calendar: React.FC<CalendarProps> = ({ onSelectDate, events = [] }) => {
    const [selectedDate, setSelectedDate] = useState<Date | null>(null);

    const handleDateClick = (date: Date | null) => {
        if(date) {
            if (selectedDate && selectedDate.getTime() === date.getTime()) {
                // Deselect the date if it's already selected
                setSelectedDate(null);
                onSelectDate(null); // Optionally, you can pass null to indicate deselection
            } else {
                // Select the clicked date
                setSelectedDate(date);
                onSelectDate(date);
            }
        }
    };


    const renderCalendar = () => {
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const currentMonth = currentDate.getMonth();
        const currentDay = currentDate.getDate();

        const daysInMonth = new Date(currentYear, currentMonth + 1, 0).getDate();
        const firstDayIndex = new Date(currentYear, currentMonth, 1).getDay();

        const days = [];

        for (let i = 0; i < firstDayIndex; i++) {
            days.push(<div key={i} className="empty"></div>);
        }

        for (let i = 1; i <= daysInMonth; i++) {
            const isSelected =
                selectedDate &&
                currentYear === selectedDate.getFullYear() &&
                currentMonth === selectedDate.getMonth() &&
                i === selectedDate.getDate();

            const isCurrentDay = currentYear === currentDate.getFullYear() &&
                currentMonth === currentDate.getMonth() &&
                i === currentDay;

            const eventsForDate = events?.filter(event => {
                const eventDate = new Date(event.date);
                return eventDate.getFullYear() === currentYear &&
                    eventDate.getMonth() === currentMonth &&
                    eventDate.getDate() === i;
            });

            days.push(
                <div
                    key={i + firstDayIndex}
                    className={`day ${isSelected ? 'selected' : ''} ${isCurrentDay ? 'current-day' : ''}`}
                    onClick={() => handleDateClick(new Date(currentYear, currentMonth, i))}
                >
                    {i}
                    {eventsForDate?.length > 0 && <span className="event-count">{eventsForDate.length}</span>}
                </div>
            );
        }

        return days;
    };

    return (
        <div className="calendar">
            <div className="days">
                <div>Sun</div>
                <div>Mon</div>
                <div>Tue</div>
                <div>Wed</div>
                <div>Thu</div>
                <div>Fri</div>
                <div>Sat</div>
            </div>
            <div className="dates">{renderCalendar()}</div>
            {selectedDate && (
                <div className="calendarEvent">
                    <div className="eventTitle">{selectedDate.toDateString()}</div>
                    <div className="eventDescription">
                        {events
                            ?.filter(event => {
                                const eventDate = new Date(event.date);
                                return eventDate.toDateString() === selectedDate!.toDateString();
                            })
                            .map((event, index) => (
                                <div key={index}>
                                    <div>{event.title}</div>
                                    <div>{event.description}</div>
                                </div>
                            ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default Calendar;