import React, {useState} from 'react';
import LoadingScreen from "../Elements/LoadingScreen/LoadingScreen";
import logo from "../logo_pull.png";
import TwitterWall from "../Elements/TwitterWall";

const HowToPlay = () => {

    return (
        <div className="centerContainer">
            <h1>Terms and Conditions</h1>
        </div>
    )
};

export default HowToPlay;