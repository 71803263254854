import React from 'react';
import './LoadingScreen.css';
import logo from '../../logo_pull.png';

interface LoadingScreenProps {
    Percent: number;
}

const LoadingScreen: React.FC<LoadingScreenProps> = ({ Percent }) => {

    return (
        <div className="loadingScreen">
            <div className="loadingScreenContent">
                <div className="loadingScreenLogo">
                    <div className="pill shake">
                        <div style={{ height: `${Percent}%` }} className="pillInsert"></div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LoadingScreen;
