import React, {useState} from 'react';
import LoadingScreen from "../Elements/LoadingScreen/LoadingScreen";
import logo from "../logo_pull.png";
import TwitterWall from "../Elements/TwitterWall";

import IsometricCube from "../Elements/Cube/IsometricCube";

const Home = () => {

    return (
        <div className="centerContainerFull fullHeightFlex">
            <div className="centerStatsContainer">
                <div className="centerStat">
                    <div className="centerStatInner">
                        <div className="centerStatIcon">
                            <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="35" height="35"
                                 fill="none" viewBox="0 0 24 24">
                                <path stroke="currentColor" stroke-width="2"
                                      d="M21 12c0 1.2-4.03 6-9 6s-9-4.8-9-6c0-1.2 4.03-6 9-6s9 4.8 9 6Z"/>
                                <path stroke="currentColor" stroke-width="2" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"/>
                            </svg>
                        </div>
                        <div className="centerStatInfo">
                            <div className="centerStatValue">
                                0
                            </div>
                            <div className="centerStatName">
                                Views
                            </div>
                        </div>
                    </div>
                </div>
                <div className="centerStat">
                    <div className="centerStatInner">
                        <div className="centerStatIcon">
                            <svg aria-hidden="true"
                                 xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"
                                 viewBox="0 0 24 24">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                      stroke-width="2"
                                      d="M12.01 6.001C6.5 1 1 8 5.782 13.001L12.011 20l6.23-7C23 8 17.5 1 12.01 6.002Z"/>
                            </svg>
                        </div>
                        <div className="centerStatInfo">
                            <div className="centerStatValue">
                                0
                            </div>
                            <div className="centerStatName">
                                Likes
                            </div>
                        </div>
                    </div>
                </div>
                <div className="centerStat">
                    <div className="centerStatInner">
                        <div className="centerStatIcon">
                            <svg aria-hidden="true"
                                 xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"
                                 viewBox="0 0 24 24">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                      stroke-width="2"
                                      d="M14.5 8.046H11V6.119c0-.921-.9-1.446-1.524-.894l-5.108 4.49a1.2 1.2 0 0 0 0 1.739l5.108 4.49c.624.556 1.524.027 1.524-.893v-1.928h2a3.023 3.023 0 0 1 3 3.046V19a5.593 5.593 0 0 0-1.5-10.954Z"/>
                            </svg>
                        </div>
                        <div className="centerStatInfo">
                            <div className="centerStatValue">
                                0
                            </div>
                            <div className="centerStatName">
                                Replies
                            </div>
                        </div>
                    </div>
                </div>
                <div className="centerStat">
                    <div className="centerStatInner">
                        <div className="centerStatIcon">
                            <svg aria-hidden="true"
                                 xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"
                                 viewBox="0 0 24 24">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                      stroke-width="2"
                                      d="M10 11V8a1 1 0 0 0-1-1H6a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h3a1 1 0 0 0 1-1Zm0 0v2a4 4 0 0 1-4 4H5m14-6V8a1 1 0 0 0-1-1h-3a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h3a1 1 0 0 0 1-1Zm0 0v2a4 4 0 0 1-4 4h-1"/>
                            </svg>
                        </div>
                        <div className="centerStatInfo">
                            <div className="centerStatValue">
                                0
                            </div>
                            <div className="centerStatName">
                                Quotes
                            </div>
                        </div>
                    </div>
                </div>
                <div className="centerStat">
                    <div className="centerStatInner">
                        <div className="centerStatIcon">
                            <svg aria-hidden="true"
                                 xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"
                                 viewBox="0 0 24 24">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                      stroke-width="2"
                                      d="M17.651 7.65a7.131 7.131 0 0 0-12.68 3.15M18.001 4v4h-4m-7.652 8.35a7.13 7.13 0 0 0 12.68-3.15M6 20v-4h4"/>
                            </svg>
                        </div>
                        <div className="centerStatInfo">
                            <div className="centerStatValue">
                                0
                            </div>
                            <div className="centerStatName">
                                Reposts
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="blockInfoContainer">
                <div className="blockInfo completed">
                    <div className="satInformation">
                        <div className="satValue">
                            MINE 3 BLOCKS
                        </div>
                        <div className="satInfo">
                            30,000 PTS
                        </div>
                    </div>
                    <div className="transactionsValue">
                        3/3 BLOCKS MINED
                    </div>
                    <div className="timeValue">
                        QUEST COMPLETE
                    </div>
                </div>
                <div className="blockInfo completed">
                    <div className="satInformation">
                        <div className="satValue">
                            LINK YOUR TWITTER
                        </div>
                        <div className="satInfo">
                            1,000 PTS
                        </div>
                    </div>
                    <div className="transactionsValue">
                        100% COMPLETE
                    </div>
                    <div className="timeValue">
                        QUEST COMPLETE
                    </div>
                </div>
                <div className="blockInfo">
                    <div className="satInformation">
                        <div className="satValue">
                            COMPLETE YOUR PROFILE
                        </div>
                        <div className="satInfo">
                            2,500 PTS
                        </div>
                    </div>
                    <div className="transactionsValue">
                        10% COMPLETE
                    </div>
                    <div className="timeValue">
                        QUEST ACTIVE
                    </div>
                </div>
                <div className="blockInfo">
                    <div className="satInformation">
                        <div className="satValue">
                            COMPLETE YOUR PROFILE
                        </div>
                        <div className="satInfo">
                            2,500 PTS
                        </div>
                    </div>
                    <div className="transactionsValue">
                        10% COMPLETE
                    </div>
                    <div className="timeValue">
                        QUEST ACTIVE
                    </div>
                </div>
            </div>
            <div className="flexFillRow">
                <div className="mainColumn">
                    <div className="gameCubeContainer">
                        <div className="gameCubeColumn">
                            <div className="gameCubeContaiers">
                                <IsometricCube value="24,000" text="Mining in 2d"/>
                                <IsometricCube value="4,000" text="Mining in 2d"/>
                                <IsometricCube value="4,000" text="Mining in 2d"/>
                                <IsometricCube value="4,000" text="Mining in 2d"/>
                                <IsometricCube value="4,000" text="Mining in 2d"/>
                                <IsometricCube value="4,000" text="Mining in 2d"/>
                                <IsometricCube value="4,000" text="Mining in 2d"/>
                                <IsometricCube value="4,000" text="Mining in 2d"/>
                                <IsometricCube value="4,000" text="Mining in 2d"/>
                                <IsometricCube value="4,000" text="Mining in 2d"/>
                                <IsometricCube value="4,000" text="Mining in 2d"/>
                            </div>
                        </div>
                        <div className="gameCubeColumn">
                            <div className="gameCubeContaiers">
                                <IsometricCube value="2,000" active={true} text="Mined 3d ago"/>
                                <IsometricCube value="2,000" active={true} text="Mined 3d ago"/>
                                <IsometricCube value="2,000" active={true} text="Mined 3d ago"/>
                                <IsometricCube value="12,000" active={true} text="Mined 3d ago"/>
                                <IsometricCube value="16,000" active={true} text="Mined 3d ago"/>
                                <IsometricCube value="16,000" active={true} text="Mined 3d ago"/>
                                <IsometricCube value="16,000" active={true} text="Mined 3d ago"/>
                                <IsometricCube value="16,000" active={true} text="Mined 3d ago"/>
                                <IsometricCube value="16,000" active={true} text="Mined 3d ago"/>
                                <IsometricCube value="16,000" active={true} text="Mined 3d ago"/>
                                <IsometricCube value="16,000" active={true} text="Mined 3d ago"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Home;
