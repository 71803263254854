import React, { useState } from 'react';

import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom'


import './App.css';

import StatsBox from "./Elements/StatsBox";
import LoadingScreen from "./Elements/LoadingScreen/LoadingScreen";
import TwitterWall from "./Elements/TwitterWall";

import Layout from "./Pages/Layout";
import Login from "./Pages/Login";

import Home from "./Pages/Home";
import HowToPlay from "./Pages/HowToPlay";
import Calculate from "./Pages/Calculate";

const App: React.FC = () => {
    // State to track user authentication status
    const [isLoggedIn, setIsLoggedIn] = useState<boolean>(true);

    return (
        <Router>
            <Routes>
                {/* Route for Login */}
                <Route path="/" element={isLoggedIn ? <Navigate to="/dashboard" /> : <Login />} />
                {/* Route for Dashboard */}
                <Route path="/dashboard" element={<Layout />}>
                    <Route path="/dashboard" index element={<Home />} />
                    <Route path="/dashboard/how-to-play" element={<HowToPlay />} />
                    <Route path="/dashboard/calculate-points" element={<Calculate />} />
                    {/* Add more nested routes inside Layout if needed */}
                </Route>
            </Routes>
        </Router>
    );
};

export default App;