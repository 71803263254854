import React, { useEffect, useState } from "react";

interface CountDownProps {
    endDate: Date;
    showUnits?: {
        days?: boolean;
        hours?: boolean;
        minutes?: boolean;
        seconds?: boolean;
    }
}

const CountDown: React.FC<CountDownProps> = ({ showUnits, endDate }) => {
    const [days, setDays] = useState<number>(0);
    const [hours, setHours] = useState<number>(0);
    const [minutes, setMinutes] = useState<number>(0);
    const [seconds, setSeconds] = useState<number>(0);

    useEffect(() => {
        const interval = setInterval(() => {
            let now = new Date();
            let difference = endDate.getTime() - now.getTime();
            setDays(Math.floor(difference / (1000 * 60 * 60 * 24)));
            setHours(Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)));
            setMinutes(Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)));
            setSeconds(Math.floor((difference % (1000 * 60)) / 1000));
        }, 1000);

        if(endDate.getTime() < new Date().getTime()) {
            clearInterval(interval);
        }

        return () => clearInterval(interval);
    }, [endDate]);

    // Function to add leading zeros
    const addLeadingZero = (num: number): string => {
        return num < 10 ? `0${num}` : `${num}`;
    };

    return (
        <div className="seasonCountdown">
            {showUnits?.days && <div className="seasonCountdownElement">
                <span className="seasonCountdownNumber">{addLeadingZero(days)}</span>
                <span className="seasonCountdownUnit">DAYS</span>
            </div>}
            {showUnits?.hours && <div className="seasonCountdownElement">
                <span className="seasonCountdownNumber">{addLeadingZero(hours)}</span>
                <span className="seasonCountdownUnit">HOURS</span>
            </div>}
            {showUnits?.minutes && <div className="seasonCountdownElement">
                <span className="seasonCountdownNumber">{addLeadingZero(minutes)}</span>
                <span className="seasonCountdownUnit">MINUTES</span>
            </div>}
            {showUnits?.seconds && <div className="seasonCountdownElement">
                <span className="seasonCountdownNumber">{addLeadingZero(seconds)}</span>
                <span className="seasonCountdownUnit">SECONDS</span>
            </div>}
        </div>
    );
};

export default CountDown;